<script setup lang="ts">
const props = withDefaults(
    defineProps<{
        centerText?: boolean;
        flavor?: string;
    }>(),
    {
        flavor: '',
    }
);

const appConfig = useAppConfig();
</script>

<template>
    <h2
        class="heading"
        :class="{
            'center-text': props.centerText,
            'theme--default':
                appConfig.theme === 'default' ||
                appConfig.theme === 'vw' ||
                appConfig.theme === 'cahf',
            'theme--audi': appConfig.theme === 'audi',
            'theme--bmw': appConfig.theme === 'bmw',
            'theme--hub': appConfig.theme === 'hub',
            'theme--vw': appConfig.theme === 'vw',
        }"
    >
        <div
            class="border-wrap"
            :class="{
                'full-width': appConfig.headingOptions.useFullWidthBar,
            }"
        >
            <span
                class="main"
                :class="{ uppercase: appConfig.headingOptions.uppercase }"
            >
                <slot></slot>
            </span>

            <span v-if="props.flavor" class="flavor">
                {{ props.flavor }}
            </span>
        </div>
    </h2>
</template>

<style lang="postcss" scoped>
.heading {
    display: flex;
    margin-bottom: 20px;

    &.center-text {
        width: 100%;
        justify-content: center;
        text-align: center;
    }

    & .border-wrap {
        & span {
            display: block;
        }

        & .flavor {
            font-size: 16px;
            color: var(--color-font-gray);
            font-family: var(--font-family);
        }
    }
}

/* Styles for the Default theme */
.theme--default {
    & .border-wrap {
        position: relative;
        padding-bottom: 10px;

        &.full-width {
            border-bottom: 6px solid;
            border-bottom-color: var(
                --border-bottom-color,
                var(--color-accent)
            );
        }

        &:not(.full-width) {
            &::after {
                content: '';
                position: absolute;
                height: 4px;
                width: 40%;
                background-color: var(
                    --border-bottom-color,
                    var(--color-accent)
                );
                top: 100%;
                left: 10%;
            }
        }

        & .main {
            font-family: var(--font-family);
            font-size: 44px;
            margin-bottom: 3px;

            &.uppercase {
                text-transform: uppercase;
            }
        }
    }
}

/* Styles for the Audi theme */
.theme--audi {
    margin-bottom: 30px;

    /* stylelint-disable-next-line no-descending-specificity */
    & .border-wrap {
        & .main {
            font-family: var(--font-family-heading);
            font-size: 36px;
            font-weight: var(--font-weight-bold);
            color: var(--color-font-dark);
            text-transform: capitalize;
        }
    }
}

/* Styles for the BMW theme */
.theme--bmw {
    border-bottom: solid 1px var(--color-font-gray);

    /* stylelint-disable-next-line no-descending-specificity */
    & .border-wrap {
        & .main {
            font-size: 24px;
            font-weight: var(--font-weight-bold);
            margin-bottom: 3px;
            padding-bottom: 10px;
        }
    }
}

.theme--hub {
    /* stylelint-disable-next-line no-descending-specificity */
    & .border-wrap {
        & .main {
            font-family: var(--font-family);
            font-size: 44px;
            margin-bottom: 3px;

            &.uppercase {
                text-transform: uppercase;
            }
        }
    }
}

/* Styles for the VW theme */
.theme--vw {
    /* stylelint-disable-next-line no-descending-specificity */
    & .border-wrap {
        & .main {
            font-family: var(--font-family-vw-head-bold);
        }
    }
}

@media screen and (max-width: 950px) {
    .theme--hub,
    .theme--default {
        & .border-wrap {
            & .main {
                font-size: 38px;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .theme--audi {
        & .border-wrap {
            & .main {
                font-size: 28px;
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .theme--hub,
    .theme--default {
        & .border-wrap {
            & .main {
                font-size: 36px;
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .theme--hub,
    .theme--default {
        & .border-wrap {
            & .main {
                font-size: 32px;
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .theme--hub,
    .theme--default {
        & .border-wrap {
            & .main {
                font-size: 28px;
            }
        }
    }

    .theme--vw {
        & .border-wrap {
            & .main {
                font-size: 28px;
                font-family: var(--font-family-vw-head-regular);
            }
        }
    }
}
</style>
